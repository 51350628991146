import React, {useState, useEffect} from 'react'
import Helmet from "react-helmet"
import * as queryString from "query-string"
import axios from 'axios'
import {Base64} from 'js-base64'
import LogoIntro from '../components/svg/LogoIntro'
import Star from '../components/svg/Star'
import Crypto from '../components/svg/Crypto'
import BoxSelect from '../components/BoxSelect'
import CreditDebitCard from '../components/svg/CreditDebitCard'
import PaypalButton from "../components/paypal/PaypalButton"
import {StaticImage} from "gatsby-plugin-image"
import initialPlans from "../config/initial-plans"
import availablePlans from "../config/available-plans"
import europeanCountries from "../config/european-countries"


const CheckoutPage = ({location}) => {

    const [userCountry, setUserCountry] = useState('')
    const [scriptsHaveLoaded, setScriptsHaveLoaded] = useState(false)
    const [currency, setCurrency] = useState('USD')
    const [testProductActive, setTestProductActive] = useState(false)
    const [selectedOption, setSelectedOption] = useState(
        initialPlans[0]
    )
    const [plans, setPlans] = useState([
        initialPlans
    ])

    const currencyLabels = {
        "EUR": "€",
        "USD": "$"
    }

    /*global Chargebee*/
    function openCheckout() {
        if (plans.length === 0) return

        console.log(currency)
        if (currency == "USD") {
            if (selectedOption.title == "Design + Technique") {
                window.location.href = "https://buy.stripe.com/9AQ7wydv8gWqcRabIJ";
            } else if (selectedOption.title == "Design") {
                window.location.href = "https://buy.stripe.com/eVa5oqcr49tY3gA5km";
            } else if (selectedOption.title == "Technique") {
                window.location.href = "https://buy.stripe.com/6oEeZ00Im6hM4kE4gj";
            }
        } else {
            if (selectedOption.title == "Design + Technique") {
                window.location.href = "https://buy.stripe.com/9AQ9EGgHk35AeZieV0";
            } else if (selectedOption.title == "Design") {
                window.location.href = "https://buy.stripe.com/aEU2ce0Im0Xs18s9AF";
            } else if (selectedOption.title == "Technique") {
                window.location.href = "https://buy.stripe.com/dR6aIKdv8gWq04o8wA";
            }
        }

    }

    function fetchLocation() {
        return axios.get(`https://tattoomastery.netlify.app/.netlify/functions/test-handler`)
    }

    function isEuropeanCountry(country) {
        return europeanCountries.includes(country)
    }

    function getCurrency(country) {
        if (country === 'United States') {
            return 'USD'
        }
        if (isEuropeanCountry(country)) {
            return 'EUR'
        }
        return 'USD'
    }

    function pushScript(src) {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script')
            script.setAttribute('src', src)
            document.body.appendChild(script)
            script.onload = () => {
                resolve()
            }
        })
    }

    function fetchScripts() {
        console.log('runs')
        pushScript("https://www.paypal.com/sdk/js?client-id=AVklYChL4JFcQfjm_tRH-bGgqcJJhwzy14kx8XqU2gbL6QoaTeWmIO85s4bnrkcvuPI7PMOaT0IWfQop").then(() => {
            setScriptsHaveLoaded(true)
        })
    }

    useEffect(() => {
        const urlParams = queryString.parse(location.search)
        const testProductActive = urlParams.testProduct
        const testProductParam = testProductActive ? true : false
        setTestProductActive(testProductParam)
        fetchLocation().then(response => {
            const currency = getCurrency(response.data.country)
            setUserCountry(response.data.country)
            setCurrency(currency)
            setPlans(availablePlans[currency])
            setSelectedOption(availablePlans[currency][0])
            fetchScripts()
        })
    }, [])

    return (
        <main>
            <Helmet>
                <meta name="description" content="Helmet application"/>
                <meta content="utf-8" http-equiv="encoding"/>
                <meta name="viewport" content="initial-scale=1"/>
                <title>Checkout - Tattoo Mastery Community</title>
                <meta class="metaTagTop" name="description" content="Learn how to create award winning tattoos."/>
                <meta class="metaTagTop" property="og:image" content="" id="social-image"/>
                <meta property="og:title" content="Checkout - Tattoo Mastery Community"/>
                <meta property="og:description" content="Learn how to create award winning tattoos."/>
                <meta property="og:type" content="website"/>
                <meta property="cf:page_code" content="NDgyMDg3NTE="/>
                <meta property="cf:mode_id" content="1"/>
                <meta property="cf:time_zone" content="UTC"/>
                <meta property="cf:app_domain" content="app.clickfunnels.com"/>
            </Helmet>
            <main>
                <div className="checkout min-h-screen w-full pb-15">
                    <header className="navigation text-center">
                        {/*<Logo className="logo inline hidden sm:inline-block"/>*/}
                        <StaticImage className="mt-4 mb-2 inline-block akermo" src={'../images/akermo.png'} alt={'Akermo'}></StaticImage>
                    </header>
                    <div className="w-full md:w-1/3 mx-auto pb-14 px-2 pt-0.5 sm:pt-0">
                        <div className="modal bg-white px-4 sm:px-8 py-4 w-full mt-4 sm:mx-0">
                            <div className="checkout-form text-center">
                                {/*<StaticImage className="inline-block w-24" src={'../images/tattoo-art.png'} alt={'Tattoo Art'}></StaticImage>*/}
                                <h2 className="text-xl text-gray">JOIN</h2>
                                <LogoIntro className="inline logo-intro"/>
                                <div className="reviews flex mt-3 text-center justify-center">
                                    <span>87 Reviews</span>
                                    <Star className="ml-3"/><Star/><Star/><Star/><Star/>
                                    <span className="ml-3">Avg 4.9/5</span>
                                </div>
                                <div className="featured-logos flex items-center justify-center mt-3">
                                    <StaticImage className="the-new-york-times" src={'../images/the-new-york-times.png'} alt={'The New York Times'}></StaticImage>
                                    <StaticImage className="inked ml-2" src={'../images/inked.png'} alt={'Inked'}></StaticImage>
                                </div>
                                {plans.length > 0 &&
                                    <BoxSelect testProductActive={testProductActive} currencyLabel={currencyLabels[currency]} selectedOption={selectedOption} setSelectedOption={setSelectedOption} options={
                                        plans
                                    }/>
                                }
                                <div className="text-left mt-1">
                                    <small className="text-xs sm:text-xs text-gray-active">By continuing I understand that this is a design course and not a state certififed tattoo license.</small>
                                    <h2 className="text-xl mt-4">CONTINUE WITH:</h2>
                                </div>
                                <button onClick={() => openCheckout()} className="credit-debit-card w-full bg-dark text-white flex items-center justify-center py-4 mt-1">
                                    <CreditDebitCard className="w-8"/> <h2 className="ml-2 mt-1">CREDIT/DEBIT CARD</h2>
                                </button>
                                <hr style={{
                                    borderTop: "1.5px dashed #C4C4C4",
                                    marginTop: "7px",
                                    borderStyle: 'dotted'
                                }}/>
                                <div className="flex">
                                    {currency && selectedOption.id &&
                                        <form className="pr-0.5 w-full" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                            <input type="hidden" name="cmd" value="_xclick"/>
                                            <input type="hidden" name="business" value="info@oscarakermo.com"/>
                                            <input type="hidden" name="lc" value="US"/>
                                            <input type="hidden" name="item_name" value={selectedOption.title}/>
                                            <input type="hidden" name="amount" value={selectedOption.price}/>
                                            <input type="hidden" name="currency_code" value={currency}/>
                                            <input type="hidden" name="button_subtype" value="services"/>
                                            <input type="hidden" name="return" value="https://training.oscarakermo.com/checkout-thank_you"/>
                                            <input type="hidden" name="no_note" value="0"/>
                                            <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHostedGuest"/>
                                            <button type="submit" className="paypal w-full border-2 border-black mt-1.5 flex justify-center">
                                                <StaticImage width={100} src={'../images/paypal.png'} alt={'Paypal'}></StaticImage>
                                            </button>
                                        </form>}
                                </div>
                                <div className="cards-and-seals mt-2 px-10">
                                    <StaticImage className="w-full sm:w-56 inline-block" src={'../images/seals.png'} alt={'Warranties'}></StaticImage>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </main>
    )
}

export default CheckoutPage
