import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom"


function PaypalButton(props) {

    const [isEligible, setIsEligible] = useState(false)
    /*global paypal*/
    const PayPalBtn = paypal.Buttons.driver("react", { React, ReactDOM })

    const createOrder = (data, actions) =>{
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: props.amount,
              },
            },
          ],
        })
    }

    const onApprove = (data, actions) => {
        return actions.order.capture();
    }

    useEffect(() => {
        //just to check if we render other elements as well
        var button = paypal.Buttons({
            fundingSource: paypal.FUNDING.PAYLATER,
            createOrder: createOrder
        })
        console.log("Pay Later Availability:")
        console.log(button.isEligible())
        if (button.isEligible()) {
            setIsEligible(true)
        }
    }, [])

    return (
        <div className="paymentPlan">
            {isEligible && <h2 className="payment-plan uppercase mt-4 mb-2 text-left">PAYMENT PLAN</h2>}
            {isEligible && <PayPalBtn
                fundingSource={paypal.FUNDING.PAYLATER}
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
            />}
            {isEligible && <div
                className="mt-2"
                data-pp-message
                data-pp-amount={props.amount}>
            </div>}
        </div>
    );
}

export default PaypalButton
