import React, {useEffect, useState} from 'react'
import ArrowDown from './svg/ArrowDown'
import ArrowUp from './svg/ArrowUp'
import BoxSelectOption from './BoxSelectOption'
import './box-select.css'

const BoxSelect = ({options, selectedOption, setSelectedOption, currencyLabel, testProductActive}) => {

    const [expanded, setExpanded] = useState(false)

	useEffect(() => {    
        // const mode = window.URLSearchParams.get('mode')
    }, [])

    return (
        <div className="box-select-container">
            {selectedOption && <div onClick={() => setExpanded(!expanded)} className="cursor-pointer box-select mt-3 flex items-center border-black border-2">
                            <div className="dropdown border-r-2 border-black block h-full flex items-center px-3">
                               {!expanded && <ArrowDown/>}
                               {expanded && <ArrowUp className="mb-1"/>}
                            </div>
                            <div className="selected-option-info px-3 flex items-center justify-between w-full">
                                <h2 className="uppercase font-bold ml-1 text-sm sm:text-base">{selectedOption.title}</h2>
                                <h2 className="text-xl">{selectedOption.price ? currencyLabel + selectedOption.price : '' }</h2>
                            </div>
                        </div>}
            {expanded && <div className="options border-2 border-black border-t-0">
                {options.length && options.map((option) => { if((option.id !== 'Tattoo-Mastery-Test-USD-Monthly') || testProductActive) return (
                  <BoxSelectOption
                      isSelected={selectedOption.id === option.id}
                      onClick={() => {
                          setSelectedOption(option)
                          setTimeout(() => {
                              setExpanded(false) 
                          }, 180)
                      }}
                      key={option.id.toString()}
                      title={option.title} 
                      subtitle={option.subtitle}
                      img={option.img}
                      price={option.price ? currencyLabel + option.price: ''}
                  />
                )
                })}
            </div>}
        </div>
    )
}

export default BoxSelect
