import * as React from "react"

function LogoIntro(props) {
  return (
    <svg width={419} height={31} viewBox="0 0 419 31" fill="none" {...props}>
      <path
        d="M59.062 1.12V6h6.44v24h5.52V6h6.44V1.12h-18.4zm29.835 6.24h.08l2.68 11.68h-5.48l2.72-11.68zM83.617 30l1.56-6.08h7.64l1.4 6.08h5.28l-6.96-28.88h-7.08L78.297 30h5.32zm16-28.88V6h6.439v24h5.52V6h6.44V1.12h-18.4zm20.999 0V6h6.44v24h5.52V6h6.44V1.12h-18.4zm22.2 19.6c0 7.12 3.76 9.68 9.12 9.68 5.36 0 9.12-2.56 9.12-9.68V10.4c0-7.12-3.76-9.68-9.12-9.68-5.36 0-9.12 2.56-9.12 9.68v10.32zm5.52-11.04c0-3.2 1.4-4.08 3.6-4.08s3.6.88 3.6 4.08v11.76c0 3.2-1.4 4.08-3.6 4.08s-3.6-.88-3.6-4.08V9.68zm17.707 11.04c0 7.12 3.76 9.68 9.12 9.68 5.36 0 9.12-2.56 9.12-9.68V10.4c0-7.12-3.76-9.68-9.12-9.68-5.36 0-9.12 2.56-9.12 9.68v10.32zm5.52-11.04c0-3.2 1.4-4.08 3.6-4.08s3.6.88 3.6 4.08v11.76c0 3.2-1.4 4.08-3.6 4.08s-3.6-.88-3.6-4.08V9.68zM206.922 30V7.28h.08l5.88 22.72h4.16l5.88-22.72h.08V30h4.72V1.12h-7.72l-5 18.96h-.08l-5-18.96h-7.72V30h4.72zm35.733-22.64h.08l2.68 11.68h-5.48l2.72-11.68zM237.375 30l1.56-6.08h7.64l1.4 6.08h5.28l-6.96-28.88h-7.08L232.055 30h5.32zm37.027-22.28c-.96-4.08-3.96-7-8.88-7-5.72 0-8.68 3.12-8.68 8.08 0 8.08 12.32 8.16 12.32 13.6 0 2.16-1.48 3.44-3.4 3.44-1.64 0-3.64-1.24-4.28-4.72l-5.44 1.16c1.48 5.68 4.96 8.12 9.44 8.12 5.8 0 9.2-3.28 9.2-8.24 0-9.04-12.32-9.36-12.32-13.72 0-1.96 1.08-3.16 3.04-3.16 1.92 0 3.12 1.16 4.04 3.88l4.96-1.44zm4.386-6.6V6h6.44v24h5.52V6h6.44V1.12h-18.4zm38.2 28.88v-4.88h-10v-7.6h7.32v-4.88h-7.32V6h9.6V1.12h-15.12V30h15.52zm24.36 0l-5.44-12.88c3.08-1.16 4.36-3.76 4.36-7.36 0-5.44-3.16-8.64-9.8-8.64h-8V30h5.52V18.24h2.64l4.8 11.76h5.92zm-13.36-24.32h2.84c2.44 0 3.92 1.2 3.92 4s-1.48 4-3.92 4h-2.84v-8zm20.884-4.56h-5.88l7.88 17.2V30h5.52V18.32l7.88-17.2h-5.88l-4.68 11.64-4.84-11.64z"
        fill="#202020"
      />
      <path fill="#202020" d="M0 16H38V18H0z" />
      <path fill="#202020" d="M381 16H419V18H381z" />
    </svg>
  )
}

export default LogoIntro
