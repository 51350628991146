import React from 'react'
import {StaticImage} from "gatsby-plugin-image";


const BoxSelectOption = (props) => {

    return (
        <div onClick={props.onClick} className={'option grid grid-cols-12 items-center p-3 hover:bg-light-gray cursor-pointer ' + (props.isSelected ? 'bg-light-gray' : '')}>
            <div className="flex col-span-9">
                <span className="option-icon hidden sm:block">
                    {props.img === 'design-and-technique' && <StaticImage placeholder="blurred" className="w-10" src={'../images/t&d.png'} alt="Design & Technique"/>}
                    {props.img === 'technique' && <StaticImage placeholder="blurred" className="w-10" src={'../images/t.png'} alt="Technique"/>}
                    {props.img === 'design' && <StaticImage placeholder="blurred" className="w-10" src={'../images/d.png'} alt="Design"/>}
                </span>
                <div className="option-info px-4 text-left">
                    <h2 className="option-title uppercase">{props.title}</h2>
                    <span className="option-subtitle block">{props.subtitle}</span>
                </div>
            </div>
            <div className="option-checkbox-container col-span-3 text-right">
                <h2 className={'option-price text-xl ' + (props.isSelected ? 'text-gray-active' : '')}>
                    {props.price}
                </h2>
            </div>
        </div>
    )

}

export default BoxSelectOption
