import * as React from "react"

function Star(props) {
  return (
    <svg width={20} height={19} viewBox="0 0 20 19" fill="none" {...props}>
      <path
        d="M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27z"
        fill={ props.color ? props.color : "#C4C4C4" }
      />
    </svg>
  )
}

export default Star
