import React from "react";

function CreditDebitCard() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      fill="none"
      viewBox="0 0 30 24"
    >
      <path
        fill="#fff"
        d="M25.207 0H4.793a4.773 4.773 0 00-3.39 1.425A4.883 4.883 0 000 4.858v13.484c0 1.287.504 2.522 1.402 3.433A4.773 4.773 0 004.793 23.2h20.414a4.773 4.773 0 003.39-1.425A4.882 4.882 0 0030 18.342V4.858a4.882 4.882 0 00-1.402-3.433A4.773 4.773 0 0025.207 0zM4.793 1.52h20.414a3.261 3.261 0 012.329.98 3.365 3.365 0 01.961 2.358v.114h-27v-.114a3.363 3.363 0 01.963-2.36 3.261 3.261 0 012.333-.977zm20.414 20.16H4.793a3.26 3.26 0 01-2.329-.98 3.364 3.364 0 01-.961-2.358V9.577h27v8.765a3.362 3.362 0 01-.963 2.36 3.291 3.291 0 01-2.333.978zm-9.54-9.777c-.001.202-.081.395-.222.537a.749.749 0 01-.532.222h-7.29a.75.75 0 01-.5-.24.766.766 0 010-1.04.75.75 0 01.5-.24h7.287a.747.747 0 01.535.222.761.761 0 01.222.54zm6.886 0c0 .202-.08.395-.222.537a.749.749 0 01-.531.222h-3.647a.75.75 0 01-.5-.24.766.766 0 010-1.04.75.75 0 01.5-.24h3.644c.2 0 .391.08.532.223.142.143.221.336.221.538h.003z"
      ></path>
    </svg>
  );
}

export default CreditDebitCard;
