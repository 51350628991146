import * as React from "react"

function ArrowUp(props) {
  return (
    <svg width={28} height={17} viewBox="0 0 28 17" fill="none" {...props}>
      <path
        d="M.506 13.817l3.171 3.172L14 6.684 24.323 16.99l3.172-3.172L14 .317.506 13.817z"
        fill="#202020"
      />
    </svg>
  )
}

export default ArrowUp
