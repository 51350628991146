export default [{
  id: 1,
  title: "Design + Technique",
  subtitle: "The complete Tattoo Mastery experience",
  price: false,
  img: 'design-and-technique',
},
{
  id: 2,
  title: "Design",
  subtitle: "Focus on digital design",
  price: false,
  img: 'design',
},
{
  id: 3,
  title: "Technique",
  subtitle: "Join me in the studio",
  price: false,
  img: 'technique',
}]