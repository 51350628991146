export default {
    'EUR': [
      {
        id: 'Tattoo-Mastery-Technique-and-Design-EUR-Monthly',
        title: "Design + Technique",
        subtitle: "The complete Tattoo Mastery experience",
        price: 835,
        img: 'design-and-technique',
        paylaterPrice: 930
      },
      {
        id: 'Tattoo-Mastery-Design-EUR-Monthly',
        title: "Design",
        subtitle: "Focus on digital design",
        price: 500,
        img: 'design',
      },
      {
        id: 'Tattoo-Mastery-Technique-EUR-Monthly',
        title: "Technique",
        subtitle: "Join me in the studio",
        price: 500,
        img: 'technique',
      }
    ],
    'USD': [
      {
        id: 'Tattoo-Mastery-Technique-and-Design-USD-Monthly',
        title: "Design + Technique",
        subtitle: "The complete Tattoo Mastery experience",
        price: 997,
        img: 'design-and-technique',
        paylaterPrice: 1100
      },
      {
        id: 'Tattoo-Mastery-Design-USD-Monthly',
        title: "Design",
        subtitle: "Focus on digital design",
        img: 'design',
        price: 599,
      },
      {
        id: 'Tattoo-Mastery-Technique-USD-Monthly',
        title: "Technique",
        subtitle: "Join me in the studio",
        img: 'technique',
        price: 599,
      },
      {
        id: 'Tattoo-Mastery-Test-USD-Monthly',
        title: "Test Product",
        subtitle: "Test product",
        img: 'technique',
        price: 1,
      }
    ]
  }