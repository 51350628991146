import * as React from "react"

function ArrowDown(props) {
  return (
    <svg width={28} height={17} viewBox="0 0 28 17" fill="none" {...props}>
      <path
        d="M27.495 3.489L24.323.316 14 10.621 3.677.316.506 3.49 14 16.989l13.495-13.5z"
        fill="#202020"
      />
    </svg>
  )
}

export default ArrowDown
